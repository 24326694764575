import capitalize from "lodash/capitalize"
import includes from "lodash/includes"
import every from "lodash/every"
import numberParse from "multi-number-parse"
import { DateTime } from  'luxon'
import config from './config'

let clientLanguage = navigator.language || navigator.userLanguage

export default {
  isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },


  getLanguageName(code) {
    switch (code) {
      case "nl": return "Dutch";
      case "en": return "English";
      case "de": return "German";
      case "fr": return "French";
      case "es": return "Spanish";
      case "it": return "Italian";
      default: return "";
    }
  },

  toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long',
    });
  },

  makeDiff(org, copy, includes, excludes, copyIfDiffs, ids) {
    includes = includes || [];
    excludes = excludes || [];
    copyIfDiffs = copyIfDiffs || [];

    let diff = {}
    let keys = Object.keys(org);

    keys.forEach((k) => {
      if(!excludes.includes(k)) {

        const org_value = org[k]
        const copy_value = copy[k]


        if(this.isObject(org_value)) {
          let innerDiff = this.makeDiff(org_value, copy_value, includes, excludes, copyIfDiffs, ids)
          if(innerDiff) {
            if(copyIfDiffs.includes(k)) diff[k] = copy_value;
            else diff[k] = innerDiff;
          }
        }

        else if(this.isArray(org_value)) {
          if(this.isArray(copy_value) && this.isObject(copy_value[0]) && copyIfDiffs.includes(k) && org_value.length != copy_value.length) {
            diff[k] = copy_value
          }
          else if(this.isArray(copy_value) && copy_value.length > 0 && this.isObject(copy_value[0])) {
            if(ids) {
              let idKey = ids[k]
              copy_value.forEach(obj => {
                let org = this.findObjectInArray(org_value, idKey, obj[idKey])
                if(org) {
                  let innerDiff = this.makeDiff(org, obj, includes, excludes, copyIfDiffs, ids)
                  if(innerDiff) {
                    if(copyIfDiffs.includes(k)) diff[k] = copy_value
                    else {
                      if(!diff[k]) diff[k] = []
                      diff[k].push(innerDiff)
                    }
                  }
                }
                else {
                  if(copyIfDiffs.includes(k)) diff[k] = copy_value
                  else {
                    if(!diff[k]) diff[k] = []
                    diff[k].push(obj)
                  }
                }
              })
            }
          }
          else {
            if(org_value.length != copy_value.length) {
              diff[k] = copy_value;
            }
            else {
              let isDifferent = false

              const os = org_value.map(x => JSON.stringify(x))
              const cs = copy_value.map(x => JSON.stringify(x))
              cs.forEach((v) => {
                if(!os.includes(v)) isDifferent = true;
              })

              if(isDifferent) diff[k] = copy_value
            }
          }
        }

        else {
          if(this.isDifferent(org_value, copy_value)) {
            diff[k] = copy_value
          }
        }
      }
    })

    if(Object.keys(diff).length != 0) {
      keys.forEach(k => {
        if(includes.includes(k)) {
          diff[k] = copy[k]
        }
      })

      return diff
    }

    return null
  },

  findObjectInArray(array, key, value) {
    let o = null

    for(var i = 0; i < array.length; ++i) {
      if(array[i][key] == value) {
        o = array[i]
        break
      }
    }

    return o
  },

  isObject(value) {
    return typeof value == 'object' && value != null && !(value instanceof Array) && !(value instanceof Date)
  },

  isArray(value) {
    return value instanceof Array
  },

  isBlank(value) {
    return value == "" || value == null || value == undefined
  },

  isDifferent(a, b) {
    return (this.isBlank(a) && !this.isBlank(b)) ||
      (!this.isBlank(a) && this.isBlank(b)) ||
      (a != b);
  },

  getTopLevelTypes() {
    return [
      "audio",
      "artist_page",
      "book",
      "brand",
      "category",
      "external",
      "magazine",
      "music_album",
      "page",
      "photo_package",
      "playlist_page",
      "podcast",
      "series",
      "video"
    ]
  },

  getContentTopLevelTypes() {
    return [
      "artist_page",
      "audio",
      "book",
      "brand",
      "external",
      "magazine",
      "music_album",
      "page",
      "photo",
      "playlist_page",
      "podcast",
      "series",
      "video"
    ]
  },

  getAudioPlaylistTypes() {
    return [
      "album_track",
      "audio",
      "podcast_episode"
    ]
  },

  getVideoPlaylistTypes() {
    return [
      "episode",
      "video"
    ]
  },

  getPlayableTopLevelTypes() {
    return [
      "audio",
      "book",
      "magazine",
      "music_album",
      "photo",
      "podcast",
      "series",
      "video"
    ]
  },

  isTopLevel(mediaType) {
    switch(mediaType) {
      case "video": return true;
      case "series": return true;
      case "book": return true;
      case "magazine": return true;
      case "brand": return true;
      case "category": return true;
      case "external": return true;
      case "photo_package": return true;
      case "audio": return true;
      case "music_album": return true;
      case "podcast": return true;
      case "page": return true;
      case "artist_page": return true;
      case "playlist_page": return true;
      default: return false;
    }
  },

  isPlayableTopLevel(mediaType) {
    return this.getPlayableTopLevelTypes().includes(mediaType)
  },

  hasDetailPage(mediaType) {
    switch(mediaType) {
      case "video": return true;
      case "series": return true;
      case "book": return true;
      case "magazine": return true;
      case "brand": return true;
      case "product_category": return true;
      case "product": return true;
      case "audio": return true;
      case "music_album": return true;
      case "podcast": return true;
      case "page": return true;
      case "artist_page": return true;
      case "playlist_page": return true;

      default: return false;
    }
  },

  hasFullMeta(mediaType) {
    switch(mediaType) {
      case "video": return true;
      case "series": return true;
      case "book": return true;
      case "magazine": return true;
      case "brand": return true;
      case "product_category": return true;
      case "product": return true;
      case "audio": return true;
      case "music_album": return true;
      case "podcast": return true;
      case "page": return true;
      case "artist_page": return true;
      case "playlist_page": return true;
      case "category": return true;
      case "photo": return true;
      case "episode": return true;
      case "issue": return true;
      case "album_track": return true;
      case "podcast_episode": return true;

      default: return false;
    }
  },


  isPlayable(mediaType) {
    switch(mediaType) {
      case "video": return true;
      case "book": return true;
      case "episode": return true;
      case "issue": return true;
      case "photo": return true;
      case "audio": return true;
      case "album_track": return true;
      case "podcast_episode": return true;
      default: return false;
    }

  },

  hasChildren: function(mediaType) {
    return mediaType == "series" ||
      mediaType == "season" ||
      mediaType == "magazine" ||
      mediaType == "year" ||
      mediaType == "music_album" ||
      mediaType == "podcast" ||
      mediaType == "photo_package" ||
      mediaType == "brand" ||
      mediaType == "product_category" ||
      mediaType == "category";
  },

  childMediaTypes(mediaType) {
    switch(mediaType) {
      case "series": return ["season"];
      case "season": return ["episode"];
      case "magazine": return ["year"];
      case "music_album": return ["album_track"];
      case "podcast": return ["podcast_episode"];
      case "year": return ["issue"];
      case "photo_package": return ["photo"];
      case "brand": return ["product_category"];
      case "product_category": return ["product", "product_category"];
      case "category": return ["category"];
      default: return [];
    }
  },

  hasAssets(mediaType) {
    switch(mediaType) {
      case "video": return true
      case "episode": return true
      case "book": return true
      case "issue": return true
      case "photo": return true
      case "audio": return true
      case "album_track": return true
      case "podcast_episode": return true
      default: return false
    }
  },

  isLivestreamable(mediaType) {
    switch(mediaType) {
      case "video": return true
      default: return false
    }
  },

  hasUPC(mediaType) {
    switch(mediaType) {
      case "product": return true
      case "series": return true
      case "video": return true
      case "audio": return true
      case "book": return true
      case "issue": return true
      case "music_album": return true
      default: return false
    }
  },

  hasISRC(mediaType) {
    switch(mediaType) {
      case "episode": return true
      case "album_track": return true
      case "video": return true
      case "audio": return true
      default: return false
    }
  },

  hasIsKidsContent(mediaType) {
    switch(mediaType) {
      case "category": return false
      case "brand": return false
      case "product_category": return false
      case "product": return false
      case "page": return false
      case "artist_page": return false
      case "playlist_page": return false
      case "external": return false
      default: return true
    }
  },

  hasQualityStamp(mediaType) {
    return this.isTopLevel(mediaType)
  },

  hasShowMetaText(mediaType) {
    return this.hasFullMeta(mediaType)
  },

  hasCoverEnabled(mediaType) {
    return this.hasFullMeta(mediaType) && mediaType != 'category'
  },

  hasSlideEnabled(mediaType) {
    return mediaType == 'product_category' || mediaType == 'category'
  },

  hasHeaderOverflow(mediaType, categoryType) {
    return mediaType == 'artist_page' || mediaType == 'playlist_page' || (mediaType == 'category' && categoryType == 'root')
  },

  hasNoRevShare(mediaType) {
    return this.isPlayable(mediaType)
  },

  hasRAExclude(mediaType) {
    return this.hasDetailPage(mediaType)
  },

  hasShoppingEnabled(mediaType) {
    return mediaType == "photo"
  },

  hasPlan(mediaType) {
    return this.isPlayable(mediaType) || this.isTopLevel(mediaType)
  },

  hasPremiumValue(mediaType, mediaPlan) {
    return this.isPlayable(mediaType) && mediaPlan != 'free'
  },

  hasCallToAction(mediaType) {
    return this.isTopLevel(mediaType) && mediaType != 'category' && mediaType != 'external'
  },

  prettify(value) {
    if(value == "page") return "Micropage"
    if(value) return value.toString().split(/[_-\s]/).map(capitalize).join(" ")
    else return ""
  },

  isInternalAsset(assetFormat) {
    return assetFormat == "video" || assetFormat == "print" || assetFormat == "image" || assetFormat == "audio"
  },

  isExternalAsset(assetFormat) {
    return assetFormat == "external_video" || assetFormat == "external_print" || assetFormat == "external_audio"
  },

  slugify(str) {
    return str
      .normalize('NFKD')
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .trim()
      .replace(/[-_\s]+/g, '-');
  },

  stringToDuration(str) {
    var tokens = str.split(":");
    if(tokens.length === 2) {
      return parseInt(tokens[0]) * 60 + parseInt(tokens[1]);
    }
    if(tokens.length === 3) {
      return parseInt(tokens[0]) * 3600 + parseInt(tokens[1]) * 60 + parseInt(tokens[2]);
    }
    if(tokens.length > 3) {
      return NaN;
    }
    return parseInt(str);
  },

  durationToString(dur) {
    if(dur || dur == 0) {
      const hours = Math.floor(dur / 3600).toString().padStart(2, '0')
      const rem = dur % 3600
      const minutes = Math.floor(rem / 60).toString().padStart(2, '0')
      const seconds = (rem % 60).toString().padStart(2, '0')

      return `${hours}:${minutes}:${seconds}`
    }
    else return ""
   }, 

  getAssetSource(assetFormat) {
    if(assetFormat == "external_print" || assetFormat == "external_video" || assetFormat == "external_audio")
      return "external"
    else
      return "internal"
  },

  indexOfById(array, id) {
    for(var i = 0; i < array.length; ++i) {
      if(array[i].id == id) return i;
    }

    return -1;
  },

  durationMinutesFormatter(seconds) {
    return new Intl.NumberFormat(clientLanguage, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(seconds / 60)
  },

  currencyFormatter(amount) {
    amount = new Intl.NumberFormat(clientLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount / 100)

    if(amount === "NaN") {
      return "-";
    } else {
      return amount;
    }
  },

  premiumPointsFormatter(points) {
    points = points ? points : 0
    return new Intl.NumberFormat(clientLanguage, { minimumFractionDigits: 0, maximumFractionDigits: 1 }).format(points / 100)
  },

  fractionalCurrencyFormatter(amount) {
    amount = new Intl.NumberFormat(clientLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 6 }).format(amount / 100)

    if(amount === "NaN") {
      return "-";
    } else {
      return amount;
    }
  },

  simpleCurrencyFormatter(amount) {
    amount = new Intl.NumberFormat(clientLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false }).format(amount / 100)

    if(amount === "NaN") {
      return "-";
    } else {
      return amount;
    }
  },

  currencyParser(amount) {
    const parts = new Intl.NumberFormat(clientLanguage, { useGrouping: false }).formatToParts(1.1)
    const decimal = parts[1].value
    return numberParse(amount, decimal)
  },

  percentageFormatter(value) {
    return new Intl.NumberFormat(clientLanguage, { minimumFractionDigits: 1, maximumFractionDigits: 2 }).format(value * 100)
  },

  dtFormatter(dt) {
    if(dt) {
      return new Date(dt).toLocaleString()
    }
    else {
      '-'
    }
  },

  dateFormatter(dt) {
    return `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}`
  },

  booleanFormatter(value) {
    if(value == true) return "Yes"
    else if(value == false) return "No"
    else "Unspecified"
  },

  arrayFormatter(value) {
    if(value) {
      if(value.length >= 0) return value.join(", ")
      else return value.replace(/[[\]]/g, '')
    }
    else return ""
  },

  mediaLabelMatch(item, filterLabels) {
    return every(filterLabels, label => includes(item.labels, label))
  },

  mediaLabelFilter(items, filter) {
    return items.filter(item => this.labelMatch(item, filter))
  },

  utcToLocalString(utcString) {
    return DateTime.fromISO(utcString, {zone: 'utc'}).setZone('local').toString()
  },

  getModifier(ev, os) {
    const ctrlKey = os == 'MacOS' ? ev.metaKey : ev.ctrlKey
    return ev.shiftKey ? "shift" : ctrlKey ? "control" : null;
  },

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  },

  linebreakSplit(str) {
    return str.split(/\r?\n|\r|\n/g)
  },

  resizeImageUrl(url, height) {
    if(config.useBunnyResize) {
      return `${url}?height=${height}`
    }
    else {
      return `https://${config.thumbor_server}/unsafe/0x${height}/${url}`
    }
  },

  async JSONHandler(resp) {
    if(resp.resp) {
      if(resp.resp.status == 200) {
        resp.json = await resp.resp.json()
        return resp
      }
    }
    else if(resp.status == 200) {
      return await resp.json()
    }

    throw {text: resp.statusText, status: resp.status || resp.resp.status}
  }
}
