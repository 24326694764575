var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sticky-top", staticStyle: { width: "100%" } },
    [
      _c("Keypress", {
        attrs: { "key-code": 70, modifiers: _vm.keyModifiers },
        on: { success: _vm.focusSearch },
      }),
      _c(
        "div",
        {
          staticClass:
            "w-100 d-flex justify-content-between pl-4 mr-4 bg-dark py-1",
        },
        [
          _c(
            "span",
            {
              staticClass: "page-title",
              staticStyle: { "margin-top": "0.75rem" },
            },
            [_vm._v(_vm._s(_vm.activePageTitle))]
          ),
          _vm.toolbarType == "home-toolbar"
            ? _c("HomeToolbar")
            : _vm.toolbarType == "media-toolbar"
            ? _c("MediaToolbar", {
                ref: "mediaBar",
                attrs: { "media-type": _vm.contentType },
              })
            : _vm.toolbarType == "keyword-toolbar"
            ? _c("KeywordToolbar", {
                attrs: { "keyword-type": _vm.contentType },
              })
            : _vm.toolbarType == "user-toolbar"
            ? _c("UserToolbar", { attrs: { "user-type": _vm.contentType } })
            : _vm.toolbarType == "affiliate-toolbar"
            ? _c("AffiliateToolbar")
            : _vm.toolbarType == "promo-code-toolbar"
            ? _c("PromoCodeToolbar")
            : _vm.toolbarType == "inbox-toolbar"
            ? _c("InboxToolbar", { attrs: { "inbox-type": _vm.contentType } })
            : _vm.toolbarType == "ddex-toolbar"
            ? _c("DdexToolbar", { attrs: { "ddex-type": _vm.contentType } })
            : _vm.toolbarType == "campaign-toolbar"
            ? _c("CampaignToolbar")
            : _vm.toolbarType == "finance-toolbar"
            ? _c("FinanceToolbar", {
                attrs: { "finance-type": _vm.contentType },
              })
            : _vm.toolbarType == "stats-toolbar"
            ? _c("StatsToolbar", { attrs: { "stats-type": _vm.contentType } })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ml-1",
              staticStyle: { "margin-right": "-5px", "margin-top": "12px" },
            },
            [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
            1
          ),
          _c(
            "b-dropdown",
            { attrs: { variant: "link", "no-caret": "", right: "" } },
            [
              _c("template", { slot: "button-content" }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "1px" } },
                  [_c("Icon", { attrs: { size: 24, icon: "menu-24px" } })],
                  1
                ),
              ]),
              _vm.$store.state.session.user &&
              (_vm.$store.state.session.user.type == "admin" ||
                _vm.$store.state.session.user.type == "editor_in_chief")
                ? [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dropCacheModalVisible = true
                          },
                        },
                      },
                      [
                        _c("Icon", { attrs: { icon: "globe" } }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v("Clear Frontend Media Cache"),
                        ]),
                      ],
                      1
                    ),
                    _c("b-dropdown-divider"),
                  ]
                : _vm._e(),
              _c("b-dropdown-item", { attrs: { disabled: "" } }, [
                _vm._v(_vm._s(_vm.user.username)),
              ]),
              _c(
                "b-dropdown-item",
                { attrs: { href: "#" }, on: { click: _vm.handleLogout } },
                [_vm._v("Signout")]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("SimpleModal", {
        attrs: {
          value: _vm.dropCacheModalVisible,
          msg: "Are you sure you want to clear the Media Cache, including dashboards and search queries?",
        },
        on: {
          ok: _vm.handleDropCache,
          cancel: function ($event) {
            _vm.dropCacheModalVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }