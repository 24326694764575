<template>
  <div class="edit-media mb-3" style="padding-bottom: 0.12rem;">
      <div>
        <b-row>
          <b-col xl>
          <div class="itemdata shadow-sm">

              <b-form class="edit-form">

                <b-form-group label="ID" label-for="id-input" horizontal :label-cols="2">
                  <id-input id="id-input" :value="mediaEdit.id"/>
                </b-form-group>

                <template v-if="mediaEdit.duplicate_of != null">
                  <b-form-group label="Duplicate of" label-for="duplicate-of-input" horizontal :label-cols="2">
                    <b-input-group>
                    <b-input id="duplicate-of-input" :value="mediaEdit.duplicate_of" readonly></b-input>
                      <b-input-group-append>
                        <b-button v-b-tooltip.hover title="Remove duplicate reference" variant="danger" @click.stop="mediaEdit.duplicate_of = null">
                          <font-awesome-icon icon="trash-alt"/>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </template>

                <b-form-group label="Fallback Lang" label-for="def-lang-input" horizontal :label-cols="2">
                  <b-input id="def-lang-input" :value="defaultLanguageName" readonly></b-input>
                </b-form-group>

                <b-form-group label="Source" label-for="asset-source-input" horizontal :label-cols="2">
                  <b-input id="asset-source-input" :value="mediaEdit.source" readonly></b-input>
                </b-form-group>

                <template v-if="media.category_type != 'root'">
                  <b-form-group label="Partner" label-for="partner-select" horizontal :label-cols="2">
                    <b-form-select id="partner-select" v-model="itemPartner" :options="partnerOptions" />
                  </b-form-group>
                </template>

                <template v-if="$config.useExternalIDs">
                  <b-form-group v-if="$helpers.hasUPC(media.type)" label="UPC" label-for="upc-input" horizontal :label-cols="2">
                    <b-form-input id="upc-input" v-model="itemUPC" ></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="$helpers.hasISRC(media.type)" label="ISRC" label-for="isrc-input" horizontal :label-cols="2">
                    <b-form-textarea id="isrc-input" v-model="itemISRC" ></b-form-textarea>
                  </b-form-group>
                  <b-form-group v-if="$helpers.hasISRC(media.type)" label="Reporting Code" label-for="reporting-code-input" horizontal :label-cols="2">
                    <b-form-input id="reporting-code-input" v-model="itemReportingCode" ></b-form-input>
                  </b-form-group>
                </template>

                <template v-if="media.type == 'music_album' || media.type == 'album_track' ||  $config.enableArtistsEverywhere" >
                  <b-form-group label="Artists" label-for="artists-comp" horizontal :label-cols="2">
                    <LabelsInput id="artists-comp" :language="mediaEdit.default_language" :labels="mediaEdit.artists" @update="saveEditArtists" label-type="artist"/>
                  </b-form-group>
                </template>

                <template v-if="$config.enableGenres && media.type != 'category'" >
                  <b-form-group label="Genres" label-for="genres-comp" horizontal :label-cols="2">
                    <LabelsInput id="genres-comp" :language="mediaEdit.default_language" :labels="mediaEdit.genres" @update="saveEditGenres" label-type="genre"/>
                  </b-form-group>
                </template>


                <template v-if="$helpers.isPlayable(media.type) || $helpers.isTopLevel(media.type)">
                  <b-form-group label="Plan" label-for="plan-select" horizontal :label-cols="2">
                    <b-form-select id="plan-select" v-model="itemPlan" :options="$store.state.media.options.plan"/>
                  </b-form-group>
                </template>

                <template v-if="media.type != 'category'">
                  <b-form-group v-if="$config.usePremiumValue && $helpers.hasPremiumValue(media.type, itemPlan) && isPremiumType(media.type)" label="Premium Value" label-for="premium-value-select" horizontal :label-cols="2">
                    <b-form-select id="premium-value-select" v-model="itemPremiumValue" :options="$store.state.media.options.premiumValue"/>
                  </b-form-group>

                  <b-form-group v-if="media.type == 'magazine' || media.type == 'series'" :label="`${$helpers.prettify(media.type)} Layout`" label-for="dashboard-position" horizontal :label-cols="2">
                    <b-form-select id="serial-layout" v-model="itemSerialLayout" :options="serialLayoutOptions" switch />
                  </b-form-group>

                  <b-form-group v-if="$helpers.hasDetailPage(media.type)" label="Dashboard Pos." label-for="dashboard-position" horizontal :label-cols="2">
                    <b-form-select id="dashboard-position" v-model="itemEmbeddedDashboardPosition" :options="[{value: 'top', text: 'Top'}, {value: 'bottom', text: 'Bottom'}]" switch />
                  </b-form-group>

                  <b-form-group label="Year of Release" label-for="year-input" horizontal :label-cols="2">
                    <b-form-input id="year-input" v-model.number="itemYear" :state="yearState"></b-form-input>
                  </b-form-group>

                  <b-form-group label="Schedule" label-for="publication-comp" horizontal :label-cols="2">
                    <Publication id="publication-comp" v-model="mediaEdit"/>
                  </b-form-group>

                  <b-form-group v-if="$helpers.hasShoppingEnabled(media.type)" label="Enable Shopping" label-for="enable-shopping" horizontal :label-cols="2">
                    <b-form-checkbox id="enable-shopping" v-model="itemShoppingEnabled" switch />
                  </b-form-group>

                  <template v-if="$helpers.isLivestreamable(media.type)">
                    <b-form-group label="Livestream" label-for="datetime-livestream-comp" horizontal :label-cols="2">
                      <b-form-checkbox id="is-livestream-checkbox" v-model="itemIsLivestream" switch />
                      <div v-if="itemIsLivestream" class="mt-2">
                        <Publication id="datetime-livestream-comp" v-model="mediaEdit" from-field="datetime_livestream_start" to-field="datetime_livestream_end"/>
                      </div>
                    </b-form-group>
                  </template>

                  <b-form-group v-if="($helpers.isTopLevel(media.type) || $helpers.isPlayable(media.type)) && media.type != 'photo_package'"  label="Labels" label-for="labels-comp" horizontal :label-cols="2">
                    <LabelsInput id="labels-comp" :language="mediaEdit.default_language" :labels="mediaEdit.labels" @update="saveEditLabels"/>
                  </b-form-group>

                  <template v-if="$helpers.hasRAExclude(media.type)">
                    <b-form-group label="R.A. Exclude" label-for="ra-exclude-checkbox" v-b-tooltip.hover title="Exclude this content from the Recently Added category" horizontal :label-cols="2">
                      <b-form-checkbox id="ra-exclude-checkbox" v-model="itemRAExclude" switch />
                    </b-form-group>
                  </template>

                  <b-form-group v-if="$helpers.hasNoRevShare(media.type)" label="Revshare TOA" label-for="no-rev-share-checkbox" horizontal :label-cols="2">
                    <b-form-checkbox id="no-rev-share-checkbox" v-model="itemNoRevShare" v-b-tooltip.hover title="Puts revenue share to own account" switch />
                  </b-form-group>
                </template>

                <b-form-group v-if="$helpers.hasShowMetaText(media.type)" label="Show Meta Text" label-for="show-meta-text" horizontal :label-cols="2">
                  <b-form-checkbox id="show-meta-text" v-model="itemShowMetaText" switch />
                </b-form-group>

                <b-form-group v-if="$helpers.isPlayableTopLevel(media.type)" label="Kids Content" label-for="is-kids-content-checkbox" horizontal :label-cols="2">
                  <b-form-checkbox id="is-kids-content-checkbox" v-model="itemIsKidsContent" switch />
                </b-form-group>

                <b-form-group v-if="$helpers.hasCoverEnabled(media.type) && itemShowMetaText" label="Enable Cover" label-for="enable-cover" horizontal :label-cols="2">
                  <b-form-checkbox id="enable-cover" v-model="itemCoverEnabled" switch />
                </b-form-group>
                <b-form-group v-if="$helpers.hasSlideEnabled(media.type)" label="Enable Slides" label-for="enable-slide" horizontal :label-cols="2">
                  <b-form-checkbox id="enable-slide" v-model="itemSlideEnabled" switch />
                </b-form-group>
                <b-form-group v-if="$helpers.hasHeaderOverflow(media.type, media.category_type)" label="Enable Header Overflow" label-for="enable-header-overflow" horizontal :label-cols="2">
                  <b-form-checkbox id="enable-header-overflow" v-model="itemHeaderOverflowEnabled" switch />
                </b-form-group>

                <b-form-group v-if="$helpers.hasQualityStamp(media.type)" label="Quality Stamp" label-for="quality-stamp-checkbox" horizontal :label-cols="2">
                  <b-form-checkbox id="quality-stamp-checkbox" v-model="itemQualityStamp" switch />
                </b-form-group>

                <template v-if="$helpers.isTopLevel(media.type)">
                  <b-form-group label="Geo Whitelist" label-for="whitelist-comp" horizontal :label-cols="2">
                    <Geos id="whitelist-comp" :url="geosUrl" v-model="itemGeoWhitelist"/>
                  </b-form-group>
                  <b-form-group label="Geo Blackist" label-for="blacklist-comp" horizontal :label-cols="2">
                    <Geos id="blacklist-comp" :url="geosUrl" v-model="itemGeoBlacklist"/>
                  </b-form-group>
                </template>

                <div v-if="(media.type == 'category' || media.type == 'product_category' || media.type == 'playlist_page') && media.category_type != 'root' && media.category_layout != null" class="py-2 my-3 rounded bg-category">
                  <b-form-group label="Enable More Page" label-for="enable-page" horizontal :label-cols="2">
                    <b-form-checkbox id="enable-page" v-model="itemPageEnabled" switch />
                  </b-form-group>
                  <div class="d-flex justify-content-start align-items-center mb-2">
                    <h5 class="font-weight-bold">Category</h5>
                  </div>
                  <b-form-group v-if="mediaEdit.category_type && mediaEdit.category_type != 'root'" label="Type" label-for="category-type-input" horizontal :label-cols="2">
                    <b-form-input id="category-type-input" :value="$helpers.prettify(mediaEdit.category_type)" readonly/>
                  </b-form-group>

                  <b-form-group v-if="mediaEdit.type == 'playlist_page'" label="Asset Format" label-for="category-asset-format-input" horizontal :label-cols="2">
                    <b-form-input id="category-asset-format-input" :value="$helpers.prettify(mediaEdit.asset_format)" readonly/>
                  </b-form-group>

                  <b-form-group v-if="media.type != 'playlist_page'" label="Ad Mode" label-for="category-ad-mode-select" horizontal :label-cols="2">
                    <b-form-select id="category-ad-mode-select" v-model="categoryAdMode" :options="categoryAdModeOptions"/>
                  </b-form-group>

                  <b-form-group label="Format" label-for="category-format-select" horizontal :label-cols="2">
                    <b-form-select id="category-format-select" v-model="categoryLayoutFormat" :options="categoryLayoutFormatOptions"/>
                  </b-form-group>

                  <b-form-group label="Formfactor" label-for="category-layout-formfactor-select" horizontal :label-cols="2">
                    <b-form-select id="category-layout-formfactor-select" v-model="categoryLayoutFormfactor" :options="categoryLayoutFormfactorOptions"/>
                  </b-form-group>

                  <b-form-group label="Size" label-for="category-layout-size-select" horizontal :label-cols="2">
                    <b-form-select id="category-layout-size-select" v-model="categoryLayoutSize" :options="categoryLayoutSizeOptions"/>
                  </b-form-group>

                  <b-form-group label="Show Titles" label-for="category-layout-show-title" horizontal :label-cols="2">
                    <b-form-checkbox id="category-layout-show-title" v-model="categoryLayoutShowTitles" switch />
                  </b-form-group>

                  <b-form-group v-if="!disableShowOverlay" label="Overlay visible" label-for="category-layout-show-overlay" horizontal :label-cols="2">
                    <b-form-checkbox id="category-layout-show-overlay" v-model="categoryLayoutShowOverlay" switch />
                  </b-form-group>

                </div>

                <b-form-group v-if="media.type == 'video' || media.type == 'episode'" label="Subtitles" label-for="subtitle-list" horizontal :label-cols="2">
                  <SubtitleList id="subtitle-list" :subtitles="mediaEdit.subtitles" :item-id="mediaEdit.id" @reload="tryReload(4000)"></SubtitleList>
                </b-form-group>
                <template v-if="$helpers.hasAssets(mediaEdit.type)">
                  <b-form-group v-if="$helpers.isExternalAsset(mediaEdit.asset_format)" label="Assets" label-for="asset-list" horizontal :label-cols="2">
                    <EmbeddedAsset id="asset-list" :media-item-id="mediaEdit.id" :assets="mediaEdit.assets" @delete-asset="deleteAsset($event)"></EmbeddedAsset>
                  </b-form-group>
                  <b-form-group v-else-if="$helpers.isInternalAsset(mediaEdit.asset_format) && mediaEdit.assets" label="Assets" label-for="asset-list" horizontal :label-cols="2">
                    <AssetList id="asset-list" :items="mediaEdit.assets" :item-id="mediaEdit.id" :format="mediaEdit.asset_format" :language="selectedLanguage" @delete-asset="deleteAsset($event)" @retranscode-asset="retranscodeAsset($event)" @update="tryReload(4000)"></AssetList>
                  </b-form-group>
                </template>

                <b-form-group v-if="hasThumbnails" label="Thumbnails" label-for="thumbnails-list" horizontal :label-cols="2">
                  <ImageList id="thumbnails-list" :item-id="mediaEdit.id" :item-type="mediaEdit.type" :language="selectedLanguage" v-model="mediaEdit.thumbnails" type="thumbnail"  @reload="tryReload(4000)"></ImageList>
                </b-form-group>

                <b-form-group v-if="mediaEdit.preview_url || mediaEdit.type == 'category' || mediaEdit.type == 'page' || mediaEdit.type == 'artist_page' || mediaEdit.type == 'playlist_page'" label="Preview url" label-for="preview-url-input" horizontal :label-cols="2">
                  <b-input-group>
                    <b-input id="preview-url-input" :value="mediaEdit.preview_url" type="text" readonly></b-input>
                    <b-input-group-append>
                      <b-button v-if="mediaEdit.type == 'category' || mediaEdit.type == 'page' || mediaEdit.type == 'artist_page' || mediaEdit.type == 'playlist_page'" variant="primary" @click="selectPreviewUrl">
                        <font-awesome-icon icon="search"/>
                      </b-button>
                      <b-button variant="danger" @click="mediaEdit.preview_url = null">
                        <font-awesome-icon icon="trash-alt"/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                 </b-form-group>

              </b-form>
            </div>
          </b-col>

          <b-col xl>
            <b-form class="edit-form shadow-sm">
              <div class="metadata">
                <div class="d-flex flex-row-reverse align-items-center edit-actions">
                  <b-button class="mx-1" variant="danger" @click.stop="$emit('close', mediaId)" v-b-tooltip.hover title="Close">
                    <font-awesome-icon icon="times"/>
                  </b-button>
                  <b-button class="mx-1" variant="primary" @click="$emit('save', {id: mediaId})" v-b-tooltip.hover title="Save">
                    <Icon icon="Save"/>
                  </b-button>
                </div>

                <b-form-group label="Metadata Language" label-for="language-select" horizontal :label-cols="2">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-form-select id="language-select" v-model="selectedLanguage" :options="$store.state.media.options.language" />
                    <b-button v-if="selectedLanguage != mediaEdit.default_language" @click="deleteMeta" v-b-tooltip.hover title="Permanently delete meta data for selected language" variant="danger">
                      <font-awesome-icon icon="trash-alt"/>
                    </b-button>
                  </div>
                </b-form-group>
                <b-form-group label="Title" label-for="title-input" horizontal :label-cols="2">
                  <b-form-input id="title-input" v-model="metaTitle"  :state="titleState"></b-form-input>
                </b-form-group>
                <b-form-group label="Slug" label-for="slug-input" horizontal :label-cols="2">
                  <b-input-group>
                    <b-form-input id="slug-input" v-model="metaSlug" :state="slugState"></b-form-input>
                    <b-button @click.stop="makeSlugFromTitle">From Title</b-button>
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Secondary Title" label-for="sec-title-input" horizontal :label-cols="2">
                  <b-input-group>
                    <b-form-input id="sec-title-input" v-model="metaSecTitle"></b-form-input>
                    <b-button v-if="$config.enableArtistsEverywhere" @click.stop="makeSecTitleFromArtists">From Artists</b-button>
                  </b-input-group>
                </b-form-group>

                <template v-if="mediaEdit.show_meta_text && $helpers.hasFullMeta(media.type)">
                  <b-form-group label="Short Description" label-for="short-desc-input" horizontal :label-cols="2">
                    <b-form-textarea id="short-desc-textarea" v-model="metaShortDescription" ></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Long Description" label-for="long-desc-textarea" horizontal :label-cols="2">
                    <b-form-textarea id="long-desc-textarea" v-model="metaLongDescription" style="height: 228px;"></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Credits" label-for="credits-edit" horizontal :label-cols="2">
                    <Credits id="credits-edit" v-model="itemCredits"></Credits>
                  </b-form-group>
                </template>

                <template v-if="$helpers.hasCallToAction(media.type)">
                    <b-form-group label="Call To Action" horizontal :label-cols="2">
                      <b-form-checkbox id="c2a-enable-checkbox" v-model="mediaEdit.call_to_action_enabled" switch />
                      <div v-if="mediaEdit.call_to_action_enabled" class="mt-2">
                        <SimpleAutoComplete v-model="metaCallToAction" :items="c2aItems"></SimpleAutoComplete>
                        <b-form-input id="c2a-link-input" class="mt-1" v-model="metaCallToActionURL" :state="c2aUrlState" placeholder="URL (starting with https://)"></b-form-input>
                      </div>
                    </b-form-group>
                </template>

                <template v-else-if="media.type == 'external'">
                  <b-form-group label="Link to" label-for="link-input" horizontal :label-cols="2">
                    <b-form-input id="link-input" v-model="metaLinkTo" placeholder="URL (starting with https://)"></b-form-input>
                  </b-form-group>
                </template>

                <b-form-group v-if="!(media.type == 'year' || media.type == 'season' || media.type == 'photo_package')" label="Search Tags" label-for="search-tags-textarea" horizontal :label-cols="2">
                  <b-form-textarea id="search-tags-textarea" v-model="metaSearchTags"></b-form-textarea>
                </b-form-group>

                <b-form-group v-if="hasCovers" label="Covers" label-for="covers-list" horizontal :label-cols="2">
                  <ImageList id="covers-list" :item-id="mediaEdit.id" :item-type="mediaEdit.type" :language="selectedLanguage" v-model="metaCovers" type="cover" :allow-copy-if-empty="Object.values($store.state.app.languages).length > 1" @copy-from="copyCoversFrom" @reload="tryReload(4000)"></ImageList>
                </b-form-group>

                <b-form-group v-if="hasSlides" label="Slides" label-for="slides-list" horizontal :label-cols="2">
                  <ImageList id="slides-list" :item-id="mediaEdit.id" :item-type="mediaEdit.type" :language="selectedLanguage" v-model="metaSlides" type="slide" :allow-copy-if-empty="Object.values($store.state.app.languages).length > 1" :allow-link-to="!mediaEdit.call_to_action_enabled" @copy-from="copyCoversFrom" @reload="tryReload(4000)"></ImageList>
                </b-form-group>
              </div>
            </b-form>
          </b-col>

        </b-row>
      </div>

      <template v-if="media.type == 'category' || media.type == 'playlist_page'">
        <EditDynamicCategory v-if="media.category_type == 'dynamic'" :language="media.default_language" :subject="media.id" :parent="media.parent" :format="(mediaEdit.category_layout || {}).format" :type="media.type" :asset-format="media.asset_format" @update="saveEditCategoryFilter"/>
        <EditManualCategory v-if="media.category_type == 'manual'" :items="categoryItems" :format="categoryLayoutFormat" :lang="media.default_language" @update="saveEditManualItems" :asset-format="media.asset_format"/>
        <RecentlyAddedCategoyContent v-if="media.category_type == 'recently-added'" :language="media.default_language" :subject="media.id"/>
        <ContentTable v-if="media.category_type == 'top-artist'" :items="categoryContent"/>
      </template>

    <SimpleModal :value='deleteMetaModalVisible' @ok="doDeleteMeta" @cancel="deleteMetaModalVisible = false" msg="Are you sure you want to permanently delete all meta data for the selected language?"/>
    <template v-if="media.type == 'category' || media.type == 'page' || media.type == 'artist_page' || media.type == 'playlist_page'">
      <SelectItem v-model="selectPreviewUrlVisible" title="Select Preview Video" :lang="media.default_language" types="video,episode" require-preview @selected="mediaEdit.preview_url = $event.preview_url"/>
    </template>
  </div>
</template>

<script>
import IdInput from "./IdInput.vue"
import Geos from "./Geos.vue"
import LabelsInput from "./LabelsInput.vue"
import ImageList from "./ImageList.vue"
import SimpleModal from "./SimpleModal.vue"
import Publication from "./Publication.vue"
import SubtitleList from "./SubtitleList.vue"
import AssetList from "./AssetList.vue"
import EmbeddedAsset from "./EmbeddedAsset.vue"
import EditDynamicCategory from "./EditDynamicCategory.vue"
import EditManualCategory from "./EditManualCategory.vue"
import ContentTable from "./ContentTable.vue"
import RecentlyAddedCategoyContent from "./RecentlyAddedCategoryContent.vue"
import SimpleAutoComplete from "./SimpleAutoComplete.vue"
import SelectItem from "./SelectItem.vue"
import Credits from "./Credits.vue"

export default {
  name: 'EditMedia',

  components: {
    IdInput,
    LabelsInput,
    Geos,
    ImageList,
    SimpleModal,
    Publication,
    SubtitleList,
    AssetList,
    EmbeddedAsset,
    EditDynamicCategory,
    EditManualCategory,
    ContentTable,
    RecentlyAddedCategoyContent,
    SimpleAutoComplete,
    SelectItem,
    Credits
  },

  props: {
    mediaId: String
  },

  data() {
    return {
      DEFAULT_EDITOR: {
        org: {
          id: this.mediaId,
          default_language: this.$config.defaultLanguage,
          assets: [],
          category_layout: {format: "cover", size: "md", formfactor: "portrait"}
        },
        edit: {
          id: this.mediaId,
          default_language: this.$config.defaultLanguage,
          assets: [],
          category_layout: {format: "cover", size: "md", formfactor: "portrait"}
        },
        currentMeta: {
          language: this.$config.defaultLanguage
        },
        selectedLanguage: this.$config.defaultLanguage
      },

      mediaDefaultLang: "nl",

      slugState: null,
      titleState: null,
      yearState: null,
      c2aUrlState: null,

      saveSuccessCountdown: 0,

      deleteMetaModalVisible: false,

      selectPreviewUrlVisible: false,

      c2aItems: [],
      c2aFilter: null

    }
  },

  computed: {
    editor() {
      return this.$store.state.media.edits[this.mediaId] || this.DEFAULT_EDITOR
    },

    media: {
      get() {
        return this.editor.org
      }
    },

    mediaEdit: {
      get() {
        return this.editor.edit
      },

      set(value) {
        this.$store.commit('media/putEditItem', value)
      }
    },

    currentMeta: {
      get() {
        return this.editor.currentMeta
      },

      set(value) {
        this.$store.commit('media/putCurrentMeta', value)
      }
    },

    selectedLanguage: {
      get() {
        return this.editor.selectedLanguage
      },

      set(value) {
        this.$store.dispatch('media/setLanguageEditor', {lang: value, id: this.mediaId})
      }
    },

    categoryAdModeOptions() {
      return this.$store.state.media.options.categoryAdMode
    },

    planName() {
      return this.$helpers.prettify(this.itemPlan)
    },

    defaultLanguageName() {
      return this.$helpers.getLanguageName(this.mediaEdit.default_language);
    },

    labelsUrl() {
      return "/admin/api/v2/labels";
    },

    genresUrl() {
      return "/admin/api/v2/keywords?language=" + this.mediaEdit.default_language;
    },

    geosUrl() {
      return "/admin/api/v2/media/geo-options"
    },

    assetSource() {
      switch(this.mediaEdit.asset_format) {
        case "external_video": return "External";
        case "external_print": return "External";
        case "external_audio": return "External";
        default: return "Internal"
      }
    },

    hasCovers() {
      const type = this.mediaEdit.type
      const categoryType = this.mediaEdit.category_type
      return type != "season" && type != "year" && type != "photo" && categoryType != 'root' && this.currentMeta.covers
    },

    hasSlides() {
      const type = this.mediaEdit.type
      return type != "season" && type != "year" && type != "photo" && this.currentMeta.slides
    },

    hasThumbnails() {
      const type = this.mediaEdit.type
      return this.$helpers.isPlayable(type)
    },

    hasDashboard() {
      const type = this.mediaEdit.type

      return type != "category" && type != "season" && type != "year"
    },

    hasFormatSelect() {
      const type = this.mediaEdit.category_type

      return (type == 'manual' || type == 'dynamic')
    },

    itemUPC: {
      get() {
        return this.mediaEdit.upc
      },
      set(val) {
        this.updateEditItem('upc', val)
      }
    },
    itemISRC: {
      get() {
        return this.mediaEdit.isrc.join('\r\n')
      },
      set(val) {
        if(val) {
          const values = this.$helpers.linebreakSplit(val).map(s => s.trim())
          this.updateEditItem('isrc', values)
        }
      }
    },

    itemReportingCode: {
      get() {
        return this.mediaEdit.reporting_code
      },
      set(val) {
        this.updateEditItem('reporting_code', val)
      }
    },

    itemPartner: {
      get() {
        return this.mediaEdit.partner_id
      },

      set(val) {
        this.updateEditItem('partner_id', val)
      }
    },

    itemPlan: {
      get() {
        return this.mediaEdit.plan
      },

      set(val) {
        this.updateEditItem('plan', val)
      }
    },

    itemPremiumValue: {
      get() {
        return this.mediaEdit.premium_value
      },

      set(val) {
        this.updateEditItem('premium_value', parseInt(val))
      }
    },

    itemIsLivestream: {
      get() {
        return this.mediaEdit.is_livestream
      },

      set(val) {
        this.updateEditItem('is_livestream', val)
      }
    },

    itemRAExclude: {
      get() {
        return this.mediaEdit.recently_added_exclude
      },

      set(val) {
        this.updateEditItem('recently_added_exclude', val)
      }
    },

    itemSerialLayout: {
      get() {
        return this.mediaEdit.serial_layout
      },

      set(val) {
        this.updateEditItem('serial_layout', val)
      }
    },

    itemEmbeddedDashboardPosition: {
      get() {
        return this.mediaEdit.embedded_dashboard_position
      },

      set(val) {
        this.updateEditItem('embedded_dashboard_position', val)
      }
    },

    itemYear: {
      get() {
        return this.mediaEdit.year
      },

      set(val) {
        this.updateEditItem('year', val)
        if(val == "" || (val >= 1800 && val <= 2100)) this.yearState = true
        else this.yearState = false
      }
    },

    itemGenre: {
      get() {
        return this.mediaEdit.genre
      },

      set(val) {
        this.updateEditItem('genre', val)
      }
    },


    itemNoRevShare: {
      get() {
        return this.mediaEdit.no_rev_share
      },

      set(val) {
        this.updateEditItem('no_rev_share', val)
      }
    },

    itemShowMetaText: {
      get() {
        return this.mediaEdit.show_meta_text
      },

      set(val) {
        this.updateEditItem('show_meta_text', val)
      }
    },

    itemCoverEnabled: {
      get() {
        return this.mediaEdit.cover_enabled
      },

      set(val) {
        this.updateEditItem('cover_enabled', val)
      }
    },

    itemPageEnabled: {
      get() {
        return this.mediaEdit.page_enabled
      },

      set(val) {
        this.updateEditItem('page_enabled', val)
      }
    },

    itemSlideEnabled: {
      get() {
        return this.mediaEdit.slide_enabled
      },

      set(val) {
        this.updateEditItem('slide_enabled', val)
      }
    },

    itemHeaderOverflowEnabled: {
      get() {
        return this.mediaEdit.header_overflow
      },

      set(val) {
        this.updateEditItem('header_overflow', val)
      }
    },

    itemIsKidsContent: {
      get() {
        return this.mediaEdit.is_kids_content
      },

      set(val) {
        this.updateEditItem('is_kids_content', val)
      }
    },

    itemQualityStamp: {
      get() {
        return this.mediaEdit.quality_stamp
      },

      set(val) {
        this.updateEditItem('quality_stamp', val)
      }
    },

    itemShoppingEnabled: {
      get() {
        return this.mediaEdit.shopping_enabled
      },

      set(val) {
        this.updateEditItem('shopping_enabled', val)
      }
    },

    itemGeoWhitelist: {
      get() {
        return this.mediaEdit.geo_whitelist
      },

      set(val) {
        this.updateEditItem('geo_whitelist', val)
      }
    },

    itemGeoBlacklist: {
      get() {
        return this.mediaEdit.geo_blacklist
      },

      set(val) {
        this.updateEditItem('geo_blacklist', val)
      }
    },

    itemCredits: {
      get() {
        return this.mediaEdit.credits
      },

      set(val) {
        this.updateEditItem('credits', val)
      }
    },

    categoryItems: {
      get() {
        return this.mediaEdit.category_items
      },

      set(value) {
        this.updateEditItem('category_items', value)
      }
    },

    categoryContent: {
      get() {
        return this.mediaEdit.category_content
      },

      set(value) {
        this.updateEditItem('category_content', value)
      }
    },

    categoryAdMode: {
      get() {
        return this.mediaEdit.category_ad_mode
      },

      set(value) {
        this.updateEditItem('category_ad_mode', value)
      }
    },

    categoryLayoutFormat: {
      get() {
        return (this.mediaEdit.category_layout || {}).format
      },

      set(value) {
        let cl = this.mediaEdit.category_layout || {}
        cl.format = value
        this.updateEditItem('category_layout', cl)
      }
    },

    categoryLayoutFormfactor: {
      get() {
        return (this.mediaEdit.category_layout || {}).formfactor
      },

      set(value) {
        let cl = this.mediaEdit.category_layout || {}
        cl.formfactor = value
        this.updateEditItem('category_layout', cl)
      }
    },

    categoryLayoutSize: {
      get() {
        return (this.mediaEdit.category_layout || {}).size
      },

      set(value) {
        let cl = this.mediaEdit.category_layout || {}
        cl.size = value
        this.updateEditItem('category_layout', cl)
      }
    },

    categoryLayoutShowTitles: {
      get() {
        return (this.mediaEdit.category_layout || {}).show_titles
      },

      set(value) {
        let cl = this.mediaEdit.category_layout || {}
        cl.show_titles= value
        this.updateEditItem('category_layout', cl)
      }
    },

    categoryLayoutShowOverlay: {
      get() {
        return (this.mediaEdit.category_layout || {}).show_overlay
      },

      set(value) {
        let cl = this.mediaEdit.category_layout || {}
        cl.show_overlay = value
        this.updateEditItem('category_layout', cl)
      }
    },

    metaTitle: {
      get() {
        return this.currentMeta.title
      },
      set(val) {
        this.updateEditMeta('title', val)
        this.titleState = !!val
      }
    },

    metaSecTitle: {
      get() {
        return this.currentMeta.secondary_title
      },
      set(val) {
        this.updateEditMeta('secondary_title', val)
      }
    },

    metaSlug: {
      get() {
        return this.currentMeta.slug
      },

      set(val) {
        this.updateEditMeta('slug', val)

        const slug = {
          language: this.currentMeta.language,
          type: this.mediaEdit.type,
          slug: val
        }

        this.$http.postJSON(
          `/admin/api/v2/slugs/${this.mediaEdit.id}/check`,
          {data: slug},
          () => {
            this.slugState = true;
          },
          (status, html) => {
            if(status == 400) {
              this.slugState = false;
            }
            else {
              this.slugState = false;
              this.$root.app.showErrorDialog(html, status.toString())
            }
          }
        )
      }
    },

    metaShortDescription: {
      get() {
        return this.currentMeta.short_description
      },

      set(val) {
        this.updateEditMeta('short_description', val)
      }
    },

    metaLongDescription: {
      get() {
        return this.currentMeta.long_description
      },

      set(val) {
        this.updateEditMeta('long_description', val)
      },
    },

    metaCallToAction: {
      get() {
        return this.currentMeta.call_to_action_text
      },

      set(val) {
        this.updateEditMeta('call_to_action_text', val)
      }
    },

    metaCallToActionURL: {
      get() {
        return this.currentMeta.call_to_action_url
      },

      set(val) {
        this.updateEditMeta('call_to_action_url', val)
        if(val && val.startsWith('https://')) { this.c2aUrlState = true }
        else if(val && !val.startsWith('https://')) { this.c2aUrlState = false }
        else { this.c2aUrlState = null }
      }
    },

    metaSocialMediaDescription: {
      get() {
        return this.currentMeta.sm_description
      },

      set(val) {
        this.updateEditMeta('sm_description', val)
      }
    },

    metaSearchTags: {
      get() {
        return this.currentMeta.search_tags
      },

      set(val) {
        this.updateEditMeta('search_tags', val)
      }
    },

    metaLinkTo: {
      get() {
        return this.currentMeta.link_to
      },

      set(val) {
        this.updateEditMeta('link_to', val)
      }
    },

    metaCovers: {
      get() {
        return this.currentMeta.covers
      },

      set(val) {
        this.updateEditMeta('covers', val)
      }
    },

    metaSlides: {
      get() {
        return this.currentMeta.slides
      },

      set(val) {
        this.updateEditMeta('slides', val)
      }
    },

    langOptions() {
      return this.$store.state.media.options.language
    },

    partnerOptions() {
      return this.$store.state.media.options.partner
    },

    serialLayoutOptions() {
      return this.$store.state.media.options.serialLayout
    },

    categoryLayoutFormatOptions() {
      return this.$store.state.media.options.format
    },

    categoryLayoutFormfactorOptions() {
      return this.$store.state.media.options.formfactor
    },

    categoryLayoutSizeOptions() {
      return this.$store.state.media.options.formatSize
    },

    disableShowOverlay() {
      const cf = this.mediaEdit.category_filter

      return !(cf && cf.type.indexOf('photo') != -1)
    }

  },

  methods: {
    isPremiumType(mediaType) {
      return this.$_.includes(this.$store.state.media.premiumTypes, mediaType)
    },

    updateEditItem(field, value) {
      let update = {}
      update[this.mediaId] = {}
      update[this.mediaId][field] = value
      this.$store.commit('media/updateEditItem', update)
    },

    updateEditMeta(field, value) {
      let update = {}
      update[this.mediaId] = {}
      update[this.mediaId][field] = value
      this.$store.commit('media/updateEditMeta', update)
    },

    getCallToActions(lang) {
      fetch(`/admin/api/v2/media/all-call-to-actions?lang=${lang}`)
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.c2aItems = resp.data
      });
    },

    setCurrentMeta(lang) {
      this.getCallToActions(lang)

      const allMeta = this.mediaEdit.meta || []
      let newMeta = allMeta.filter(m => m.language == lang)

      if(newMeta.length == 0) newMeta[0] = {
        language: lang
      }

      this.$store.commit('media/putCurrentMeta', newMeta[0])
    },

    deleteMeta() {
      this.deleteMetaModalVisible = true
    },

    doDeleteMeta() {
      this.$store.dispatch('media/deleteEditMeta', {lang: this.selectedLanguage, id: this.mediaId})
      .then(() => {
        this.$root.app.showAlert("Language metadata successfully deleted", "success")
      })
      .catch(({text}) => {
         this.$root.app.showAlert(`Error deleting language metadata: ${text}`, 'danger')
      })
    },

    clear() {
      this.closeModalVisible = false
      this.slugState = null
      this.titleState = null
    },

    makeSlugFromTitle() {
      const slug = {
        language: this.currentMeta.language,
        type: this.mediaEdit.type,
        slug: this.metaTitle
      }
      this.$http.postJSON(
        `/admin/api/v2/slugs/${this.mediaEdit.id}/make`,
        {data: slug},
        (resp) => {
          this.metaSlug = resp.data
          this.slugState = true
        },
        (status, html) => this.$root.app.showErrorDialog(html, status.toString())
      )
    },

    makeSecTitleFromArtists() {
      this.metaSecTitle = (this.mediaEdit.artists || []).join(', ')
    },

    // TODO
    copyCoversFrom(lang) {
      const allMeta = this.mediaEdit.meta
      let images = []

      for(var i = 0; i < allMeta.length; ++i) {
        if(allMeta[i].language == lang) {
          images = this.$_.cloneDeep(allMeta[i].covers)
          break
        }
      }
      this.$log.debug(images)
      if(images.length > 0) {
        this.$http.postJSON(
          `admin/api/v2/media/${this.mediaId}/add-covers`,
          {data: {covers: images, language: this.currentMeta.language}},
          () => {
            this.$set(this.currentMeta, "covers", images)
          },
          (status, html) => this.$root.app.showErrorDialog(html, status.toString())
        )
      }
    },

    // TODO
    deleteAsset(id) {
      let newAssets = this.mediaEdit.assets.filter(asset => asset.id != id)
      this.$set(this.mediaEdit, "assets", newAssets)
    },

    // TODO
    retranscodeAsset(id) {
      let newAssets = this.mediaEdit.assets.map(asset => {
        if(asset.id == id) {
          asset.status = "retranscoding"
        }

        return asset;
      })
      this.$set(this.mediaEdit, "assets", newAssets)
    },

    saveEditLabels(labels) {
      this.updateEditItem('labels', labels)
    },

    saveEditArtists(artists) {
      this.updateEditItem('artists', artists)
    },

    saveEditGenres(genres) {
      this.updateEditItem('genres', genres)
    },

    saveEditCategoryFilter(filter) {
      this.mediaEdit.category_filter = filter
    },

    saveEditManualItems(items) {
      this.categoryItems = items.map((item, ndx) => {
        return {id: item.id, position: ndx + 1}
      })
    },

    saveEditWhitelist(whitelist) {
      this.updateEditItem('geo_whitelist', whitelist)
    },

    saveEditBlacklist(blacklist) {
      this.updateEditItem('geo_blacklist', blacklist)
    },

    tryReload(delay) {
      setTimeout(function() {
        this.$store.dispatch('media/fetchEditItem', this.mediaEdit.id)
        .then(json => {
          this.$store.commit('media/putEditItem', json.data)
        })
      }.bind(this), delay)
    },

    selectPreviewUrl() {
      this.selectPreviewUrlVisible = true
    }
  },

}
</script>


<style scoped>
#button-container {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.edit-media {
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #17638A;
  background: #0000007D 0% 0% no-repeat padding-box;
}

#edit-form {
  text-align: right;
}

.jumbotron {
  margin-top: -1.3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.edit-actions {
  margin-bottom: 20px;
}

.metadata {
  padding: 1rem;
}

.itemdata {
  padding: 1rem;

}

.form-control {
  background-color: #1B3B5B;
}

.custom-select {
  background-color: #1B3B5B;
}

.col-xl {
  padding-left: 0;
  padding-right: 0;
}

.bg-category {
}

</style>

