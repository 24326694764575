var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between mb-2" },
        [
          _c("b-input", {
            attrs: { list: "default-credit-keys" },
            model: {
              value: _vm.creditsKey,
              callback: function ($$v) {
                _vm.creditsKey = $$v
              },
              expression: "creditsKey",
            },
          }),
          _c("b-input", {
            model: {
              value: _vm.creditsValue,
              callback: function ($$v) {
                _vm.creditsValue = $$v
              },
              expression: "creditsValue",
            },
          }),
          _c("b-button", { on: { click: _vm.addCredits } }, [_vm._v("+")]),
          _c(
            "datalist",
            { attrs: { id: "default-credit-keys" } },
            _vm._l(_vm.defaultKeys, function (key) {
              return _c("option", { key: key }, [_vm._v(_vm._s(key))])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "draggable",
        {
          model: {
            value: _vm.credits,
            callback: function ($$v) {
              _vm.credits = $$v
            },
            expression: "credits",
          },
        },
        _vm._l(_vm.credits, function (cs) {
          return _c(
            "div",
            { key: cs[0] + cs[1], staticClass: "font-weight-bold" },
            _vm._l(cs[1], function (c, index) {
              return _c(
                "div",
                { key: c, staticClass: "d-flex justify-content-between" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "font-weight-bold",
                      style: `cursor: pointer; visibility: ${
                        index == 0 ? "visible" : "hidden"
                      };`,
                      on: {
                        click: function ($event) {
                          return _vm.setKey(cs[0])
                        },
                      },
                    },
                    [_vm._v(_vm._s(cs[0]))]
                  ),
                  _c(
                    "span",
                    [
                      _c(
                        "span",
                        {
                          staticClass: "mr-2",
                          staticStyle: { "text-align": "right" },
                        },
                        [_vm._v(_vm._s(c))]
                      ),
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.removeCredits(
                                _vm.credits.indexOf(cs),
                                cs[1].indexOf(c)
                              )
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "trash-alt" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }