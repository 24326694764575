<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <b-input v-model="creditsKey" list="default-credit-keys"></b-input>
      <b-input v-model="creditsValue"></b-input>
      <b-button @click="addCredits">+</b-button>
      <datalist id="default-credit-keys">
        <option v-for="key in defaultKeys" :key="key">{{ key }}</option>
      </datalist>
    </div>
    <draggable v-model="credits">
      <div v-for="cs in credits" :key="cs[0] + cs[1]" class="font-weight-bold">
        <div v-for="(c, index) in cs[1]" :key="c" class="d-flex justify-content-between"> 
          <div @click="setKey(cs[0])" class="font-weight-bold" :style="`cursor: pointer; visibility: ${index == 0 ? 'visible' : 'hidden'};`">{{cs[0]}}</div>
          <span>
            <span style="text-align: right;" class="mr-2">{{c}}</span>
            <b-button @click="removeCredits(credits.indexOf(cs), cs[1].indexOf(c))">
              <font-awesome-icon icon="trash-alt"/>
            </b-button>
          </span>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import Draggable from "vuedraggable"

export default {
  name: 'Credits',

  components: {
    Draggable
  },

  data: function() {
    return {
      credits: [],
      creditsKey: "",
      creditsValue: ""
    };
  },
  props: {
    value: Array
  },

  watch: {
    value(v) {
      if(v && v != this.credits) {
        this.credits = this.$_cloneDeep(v)
      }
    }
  },

  computed: {
    defaultKeys() {
      return this.$store.state.media.options.defaultCreditKeys
    }
  },

  methods: {
    addCredits() {
      this.creditsKey = this.creditsKey.trim().toLowerCase()
      this.creditsValue = this.creditsValue.trim()

      if(this.creditsKey != "" && this.creditsValue != "") {
        let keyExists = false
        this.credits = this.credits.map(c => {
          if(c[0] == this.creditsKey) {
            c[1].push(this.creditsValue)
            keyExists = true
          }

          return c
        })

        if(!keyExists) {
          this.credits.push([this.creditsKey, [this.creditsValue]])
        }
        
        this.creditsKey = ""
        this.creditsValue = ""
        this.$emit('input', this.credits)
      }
    },

    removeCredits(index, subIndex) {
      if(this.credits[index][1].length > 1) {
        this.credits[index][1].splice(subIndex, 1)
        this.credits = this.credits.map(x => x)
      }
      else {
        this.credits.splice(index, 1)
      }

      this.$emit('input', this.credits)
    },

    setKey(key) {
      this.creditsKey = key
    }
  },

  created() {
    this.credits = this.$_.cloneDeep(this.value) || []
  }
}
</script>

<style scoped>
</style>
