var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: "Add Affiliate",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { ok: _vm.createAffiliate, hidden: _vm.clear },
    },
    [
      _c(
        "form",
        { attrs: { autocomplete: "off" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: "ID", "label-for": "id-input", horizontal: "" } },
            [_c("id-input", { attrs: { id: "id-input", value: _vm.item.id } })],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Description",
                "label-for": "description-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "description-input" },
                model: {
                  value: _vm.item.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "description", $$v)
                  },
                  expression: "item.description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Type",
                "label-for": "affiliates-select-type",
                horizontal: "",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "affiliates-select-type",
                  options: _vm.typeOptions,
                },
                model: {
                  value: _vm.item.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "type", $$v)
                  },
                  expression: "item.type",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Partner",
                "label-for": "affiliates-select-partner",
                horizontal: "",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "affiliates-select-partner",
                  options: _vm.partnerOptions,
                },
                model: {
                  value: _vm.item.partner_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "partner_id", $$v)
                  },
                  expression: "item.partner_id",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Schedule",
                "label-for": "publication-comp",
                horizontal: "",
              },
            },
            [
              _c("Publication", {
                attrs: {
                  id: "publication-comp",
                  "from-field": "active_from",
                  "to-field": "active_to",
                },
                model: {
                  value: _vm.item,
                  callback: function ($$v) {
                    _vm.item = $$v
                  },
                  expression: "item",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Registration URL",
                "label-for": "url-input",
                horizontal: "",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { id: "url-input" },
                    model: {
                      value: _vm.item.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "url", $$v)
                      },
                      expression: "item.url",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: {
                            title: "Use Default URL",
                            variant: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.useDefaultUrl.apply(null, arguments)
                            },
                          },
                        },
                        [_c("Icon", { attrs: { icon: "globe" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Affiliate URL",
                "label-for": "link-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "link-input", readonly: "" },
                model: {
                  value: _vm.affiliateUrl,
                  callback: function ($$v) {
                    _vm.affiliateUrl = $$v
                  },
                  expression: "affiliateUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Embed Promo Code",
                "label-for": "embedded-promo-code",
                horizontal: "",
              },
            },
            [
              _c(
                "div",
                { attrs: { id: "embedded-promo-code" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row-reverse align-items-center mb-2",
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pressed: _vm.usePromoCode,
                            variant: _vm.usePromoCode ? "success" : "warning",
                          },
                          on: {
                            "update:pressed": function ($event) {
                              _vm.usePromoCode = $event
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.usePromoCode ? "Disable" : "Enable")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.usePromoCode
                    ? _c("b-form-input", {
                        model: {
                          value: _vm.promoCode.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.promoCode, "code", $$v)
                          },
                          expression: "promoCode.code",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.item.type == "recurring"
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Recurring compensation amount",
                    "label-for": "amount-input",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "amount-input" },
                    model: {
                      value: _vm.recurringCompensation,
                      callback: function ($$v) {
                        _vm.recurringCompensation = $$v
                      },
                      expression: "recurringCompensation",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Offers",
                "label-for": "affiliate-offer-editor",
                horizontal: "",
              },
            },
            [
              _c("AffiliateOffers", {
                attrs: {
                  id: "affiliate-offer-editor",
                  type: _vm.item.type,
                  "affiliate-compensations": _vm.item.affiliate_compensations,
                  "use-promo-code": _vm.usePromoCode,
                },
                on: { update: _vm.updateAffiliateCompensations },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.alert.dismissCountdown,
                dismissible: "",
                variant: _vm.alert.variant,
              },
              on: {
                dismissed: function ($event) {
                  _vm.alert.dismissCountdown = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }