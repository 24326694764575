<template>
<b-modal hide-footer size="xl" v-model="showOrders" title="Order Overview">
  <b-container fluid>
    <b-row>
      <b-col v-if="activeCard">
        <b-card title="Active Payment Card">
            <b-row>
              <b-col :cols="3">
                <strong>Card Type</strong>
              </b-col>
                <span>{{ activeCard.method }}</span>
              <b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col :cols="3">
                <strong>Created</strong>
              </b-col>
                <span>{{ $helpers.dtFormatter(activeCard.inserted_at) }}</span>
              <b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col :cols="3">
                <strong>Recurring</strong>
              </b-col>
                <span>{{ activeCard.recurring ? 'Yes' : 'No' }}</span>
              <b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col :cols="3">
                <strong>Reference</strong>
              </b-col>
                <span>{{ activeCard.id }}</span>
              <b-col>
              </b-col>
            </b-row>
        </b-card>
      </b-col>
      <b-col v-if='allCards.length > 0'>
        <b-card title="All Payment Cards">
          <div v-for="card in allCards" :key="card.id" class="py-2">
            <b-row>
              <b-col :cols="3">
                <strong>Card Type</strong>
              </b-col>
                <span>{{ card.method }}</span>
              <b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col :cols="3">
                <strong>Created</strong>
              </b-col>
                <span>{{ $helpers.dtFormatter(card.inserted_at) }}</span>
              <b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col :cols="3">
                <strong>Recurring</strong>
              </b-col>
                <span>{{ card.recurring ? 'Yes' : 'No' }}</span>
              <b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col :cols="3">
                <strong>Reference</strong>
              </b-col>
                <span>{{ card.id }}</span>
              <b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col :cols="3">
                <strong>Status</strong>
              </b-col>
                <span>{{ card.status }}</span>
              <b-col>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <br/>
    <b-card title="Orders">
      <b-table hover striped small :fields="orderFields" :items="orders">
        <template v-slot:cell(show_details)="row">
          <div class="d-flex justify-content-center">
            <b-button size="sm" variant="primary" @click="row.toggleDetails" class="mr-2">
              <font-awesome-icon icon="info"/>
            </b-button>
          </div>
        </template>
        <template v-slot:row-details="row">
          <b-container>
            <form autocomplete="off">
              <b-form-group label="ID" label-for="id-input" horizontal>
                <b-form-input id="id-input" :value="row.item.id" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Inserted At" label-for="inserted-at-input" horizontal>
                <b-form-input id="inserted-at-input" :value="$helpers.dtFormatter(row.item.inserted_at)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Product Type" label-for="order-input-product-type" horizontal>
                <b-form-input id="order-input-product-type" :value="$_.capitalize(row.item.product_type)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Product Plan" label-for="order-input-product-plan" horizontal>
                <b-form-input id="order-input-product-plan" :value="$_.capitalize(row.item.product_plan)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Product Name" label-for="order-input-product-name" horizontal>
                <b-form-input id="order-input-product-name" :value="$_.capitalize(row.item.product_name)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Offer Name" label-for="order-input-offer-name" horizontal>
                <b-form-input id="order-input-offer-name" :value="$_.capitalize(row.item.offer_name)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Offer Currency" label-for="order-input-offer-name" horizontal>
                <b-form-input id="order-input-offer-name" :value="row.item.offer_currency" readonly></b-form-input>
              </b-form-group>
              <b-form-group v-if="row.item.partner_id" label="Partner ID" label-for="order-input-partner-id" horizontal>
                <b-form-input id="order-input-partner-id" :value="row.item.partner_id" readonly></b-form-input>
              </b-form-group>
              <b-form-group v-if="row.item.partner_id" label="Partner Name" label-for="order-input-partner" horizontal>
                <b-form-input id="order-input-partner" :value="row.item.organisation" readonly></b-form-input>
              </b-form-group>
              <b-form-group v-if="row.item.affiliate_id" label="Affiliate ID" label-for="order-input-affiliate" horizontal>
                <b-form-input id="order-input-affiliate" :value="row.item.affiliate_id" readonly></b-form-input>
              </b-form-group>
              <b-form-group v-if="row.item.promo_code_id" label="Promo Code ID" label-for="order-input-promo" horizontal>
                <b-form-input id="order-input-promo" :value="row.item.promo_code_id" readonly></b-form-input>
              </b-form-group>
              <b-form-group v-if="row.item.applied_discount" label="Applied Discount" label-for="order-input-applied-discount" horizontal>
                <b-form-input id="order-input-applied-discount" :value="row.item.applied_discount" readonly></b-form-input>
              </b-form-group>
            </form>
          </b-container>

        </template>

      </b-table>
    </b-card>
    <b-card title="Payments">
      <b-table hover small striped :fields="paymentFields" :items="payments">
        <template #cell(invoice_url)="url">
          <a v-if="!!url.value" :href="url.value" target="_blank">Download</a>
        </template>
      </b-table>
    </b-card>

  </b-container>
</b-modal>
</template>

<script>
export default {
  name: 'CustomerOrders',

  data: function() {
    return {
      showOrders: false,
      orderFields: [
        {key: 'id', label: 'ID'},
        {key: 'offer_name', label: 'Offer Name'},
        {key: 'organisation', label: 'Organisation'},
        {key: 'offer_currency', label: 'Currency'},
        {key: 'status', label: 'Status'},
        {key: 'inserted_at', label: 'Created', formatter: this.$helpers.dtFormatter},
        {key: 'show_details', label: 'Details'}
      ],
      orders: [],
      activeCard: null,
      allCards: [],

      paymentFields: [
        {key: 'inserted_at', label: 'Created', formatter: this.$helpers.dtFormatter},
        {key: 'currency_code', label: 'Currency'},
        {key: 'amount', label: 'Amount'},
        {key: 'merchant_reference', label: 'Merchant Reference'},
        {key: 'status', label: 'Status'},
        {key: 'invoice_url', label: 'Invoice'}
      ],
      payments: []
    };
  },

  props: {

  },

  methods: {
    show(userId) {
      fetch(`/admin/api/v2/users/${userId}/payment-history`)
      .then(resp => {
        if(resp.status == 200) {
          return resp.json()
        }
        else throw Error(resp.statusText)
      })
      .then(resp => {
        this.orders = resp.data.orders.map(o => {
          o.product_name = o.offer.product.name
          o.product_type = o.offer.product_type
          o.product_plan = o.offer.product.type
          o.offer_name = o.offer.name
          o.offer_currency = o.offer.currency_code
          o.partner_id = o.affiliate ? o.affiliate.partner_id : null

          return o
        })
        this.activeCard = resp.data.active_card
        this.allCards = resp.data.cards

        this.showOrders = true

        fetch(`/admin/api/v2/users/${userId}/payments`)
        .then(resp => {
          if(resp.status == 200) {
            return resp.json()
          }
          else throw Error(resp.statusText)
        })
        .then(resp => {
          this.payments = resp.data.map(p => {
            const payment = p.payment
            payment.invoice_url = p.invoice_url
            return payment
          })
        })
        .catch(() => {
          this.$root.app.showAlert('Error getting user payments', 'danger')
        })

      })
      .catch(() => {
        this.$root.app.showAlert('Error getting user orders', 'danger')
      })
    }
  }
}
</script>

<style scoped>
</style>
