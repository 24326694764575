<template>
  <div style="margin-top: 2rem">

    <h5 class="mb-4">
      <span >Month Total: </span>
      <b-badge>{{monthTotal}}</b-badge>
    </h5>

    <b-row v-if="affiliatePartnerItems.length > 0" class="mr-0 mt-4">
      <b-col>
        <h5 class="mt-4" style="margin-bottom: 2rem; margin-top: 2rem;">Partner Totals</h5>

        <b-table class="mt-3" :items="affiliatePartnerItems" :fields="partnerFields" ref="table" striped :per-page="perPagePartner" :current-page="currentPagePartner">
        </b-table>

        <div v-if="perPagePartner" class="d-flex justify-content-center">
          <b-pagination :total-rows="affiliatePartnerItems.length" :per-page="perPagePartner" v-model="currentPagePartner"/>
        </div>
      </b-col>
      <b-col>
      </b-col>
    </b-row>


    <h5 style="margin-top: 4rem">Records</h5>
    <div id="user-count-stats" class="d-flex justify-content-between align-items-center my-4">
      <b-form inline class="">
        <label class="mr-sm-2" for="rev-share-select-partner">Partner</label>
        <b-form-select class="mr-2" id="rev-share-select-partner" :value="selectedPartner" @input="updatePartner($event)" :options="partnerOptions"></b-form-select>
      </b-form>

      <b-button variant="primary" @click="exportRecords">
        <Icon icon="export"/>
        Export Records
      </b-button>
    </div>


    <b-table class="mt-3" :items="affiliateItems" :fields="compensationFields" ref="table" striped hover small :per-page="perPage" :current-page="currentPage"/>

    <div v-if="perPage" class="d-flex justify-content-center">
      <b-pagination :total-rows="affiliateItems.length" :per-page="perPage" v-model="currentPage"/>
    </div>

    <iframe id="export-affiliates-frame" style="display:none;"></iframe>
  </div>
</template>

<script>
export default {
  name: 'AffiliateOverview',

  data: function() {
    return {
      partnerFields: [
         {key: 'partner', label: 'Partner', sortable: true},
         {key: 'fulfillments', label: 'Fulfillments', sortable: true, tdClass: "text-right"},
         {key: 'amount', label: 'Billable Amount (€)', sortable: true, formatter: this.$helpers.currencyFormatter, tdClass: "text-right"}
      ],

      compensationFields: [
         {key: 'affiliate_id', label: 'ID', sortable: true},
         {key: 'affiliate_type', label: 'Type', sortable: true},
         {key: 'partner', label: 'Partner', sortable: true},
         {key: 'offer', label: 'Offer', sortable: true},
         {key: 'geo', label: 'Country', sortable: true},
         {key: 'user', label: 'User', sortable: true},
         {key: 'amount', label: 'Amount (€)', sortable: true, formatter: this.$helpers.currencyFormatter, tdClass: "text-right"},
         {key: 'inserted_at', label: 'Created At', sortable: true, formatter: this.$helpers.dtFormatter}
      ],

      currentPage: 1,
      perPage: 20,

      currentPagePartner: 1,
      perPagePartner: 20,

      partnerOptions: [],
      selectedPartner: "__all__"

    };
  },

  computed: {
    monthTotal() {
      let total = 0
      this.affiliatePartnerItems.forEach(item => total += item.amount)

      return this.$helpers.currencyFormatter(total)
    },

    selectedYear() {
      return this.$store.state.finance.selectedYear
    },

    selectedMonth() {
      return this.$store.state.finance.selectedMonth
    },

    affiliateItems() {
      return this.$store.state.finance.affiliateItems.filter(rec => (this.selectedPartner == "__all__") || (rec.partner_id == this.selectedPartner))
    },

    affiliatePartnerItems() {
      return this.$store.state.finance.affiliatePartnerItems.filter(item => item.amount != 0 || item.fulfillments != 0)
    },
},

  methods: {
    getProviderOptions() {
      fetch("/admin/api/v2/partners/select-options?include-all")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.partnerOptions = resp.data
      });
    },

    updatePartner(partner) {
      this.selectedPartner = partner
    },

    exportRecords() {
      let payload = {
        data: {
          year: this.selectedYear,
          month: this.selectedMonth,
          partner_id: this.selectedPartner
        }
      }

      this.$http.postJSON(
        "/admin/api/v2/affiliates/build-export",
        payload,
        (resp) => {
          let file_name = "/admin/api/v2/affiliates/download-export/" + resp.data;
          document.getElementById('export-affiliates-frame').src = file_name;
        },
        (status, html) => {
          this.clear()
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    },
  },

  created() {
    this.getProviderOptions()
    this.$root.$on('rev-share-update', this.update)
  },

  beforeDestroy() {
    this.$root.$off('rev-share-update', this.update)
  }
}
</script>

<style scoped>
</style>
