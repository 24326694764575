var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { title: "Add Promo code" },
      on: { ok: _vm.createPromoCode, hidden: _vm.clear },
    },
    [
      _c(
        "form",
        { attrs: { autocomplete: "off" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Code",
                "label-for": "code-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "code-input" },
                model: {
                  value: _vm.item.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "code", $$v)
                  },
                  expression: "item.code",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Description",
                "label-for": "description-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "description-input" },
                model: {
                  value: _vm.item.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "description", $$v)
                  },
                  expression: "item.description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Partner",
                "label-for": "promo-code-select-partner",
                horizontal: "",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "promo-code-select-partner",
                  options: _vm.partnerOptions,
                },
                model: {
                  value: _vm.item.partner_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "partner_id", $$v)
                  },
                  expression: "item.partner_id",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Schedule",
                "label-for": "publication-comp",
                horizontal: "",
              },
            },
            [
              _c("Publication", {
                attrs: {
                  id: "publication-comp",
                  "from-field": "active_from",
                  "to-field": "active_to",
                },
                model: {
                  value: _vm.item,
                  callback: function ($$v) {
                    _vm.item = $$v
                  },
                  expression: "item",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Offers",
                "label-for": "promo-code-offer-editor",
                horizontal: "",
              },
            },
            [
              _c("PromoCodeOffers", {
                attrs: {
                  id: "promo-code-offer-editor",
                  type: _vm.item.type,
                  discounts: _vm.item.discounts,
                },
                on: { update: _vm.updateDiscounts },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.alert.dismissCountdown,
                dismissible: "",
                variant: _vm.alert.variant,
              },
              on: {
                dismissed: function ($event) {
                  _vm.alert.dismissCountdown = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }